<template>
  <div id="app">
    <router-view />
    <Menu :is-opened="isOpen" />
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'

export default {
  name: 'App',
  components: {
    Menu
  },
  computed: {
    isOpen () {
      return this.$store.state.menuOpened
    }
  }
}
</script>

<style lang="scss">
  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-size-adjust: none;
    @include font-base;
    color: #000;
    background: #fff;
    min-width: 320px;
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
  }
</style>
