import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    auth: {
      username: 'Courier',
      password: 'Rehmth1324',
    },
    auth_error: false,
    isAdmin: false,
    courier: '',
    orders: [],
    order: {},
    order_id: '',
    courier_id: '',
    couriers: [],
    courier_card: {},
    salary: [],
    menuOpened: false,
    showSuccessOrders: false,
    delay: '',
    // API: {
    //   CHECK_AUTH: 'http://app.serverss.ru/UEOHIE/hs/Delivery/CheckAuth',
    //   GET_ORDERS: 'http://app.serverss.ru/UEOHIE/hs/Delivery/GetOrders',
    //   GET_ORDER:  'http://app.serverss.ru/UEOHIE/hs/Delivery/GetOrderInfo',
    //   GET_SALARY: 'http://app.serverss.ru/UEOHIE/hs/Delivery/GetSettlements',
    //   ORDER_DONE: 'http://app.serverss.ru/UEOHIE/hs/Delivery/SetOrderDone'
    // }
    // API: {
    //   CHECK_AUTH: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/CheckAuth',
    //   GET_ORDERS: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/GetOrders',
    //   GET_ORDER: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/GetOrderInfo',
    //   GET_SALARY: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/GetSettlements',
    //   ORDER_DONE: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/SetOrderDone',
    //   ORDER_DELAY: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/SetOrderDelay',
    //   GET_COURIERS: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/GetCouriers',
    //   GET_COURIER: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/GetCourier',
    //   EDIT_COURIER: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/EditCourier',
    //   GET_NEW_CODE: 'https://1c.pizzaninja.ru/dostavka_test/hs/Delivery/GetNewCode',
    // },
    API: {
      CHECK_AUTH: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/CheckAuth',
      GET_ORDERS: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/GetOrders',
      GET_ORDER: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/GetOrderInfo',
      GET_SALARY: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/GetSettlements',
      ORDER_DONE: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/SetOrderDone',
      ORDER_DELAY: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/SetOrderDelay',
      GET_COURIERS: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/GetCouriers',
      GET_COURIER: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/GetCourier',
      EDIT_COURIER: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/EditCourier',
      GET_NEW_CODE: 'https://1c.pizzaninja.ru/dostavka/hs/Delivery/GetNewCode',
    }
  },
  mutations: {
    CHECK_AUTH(state, data) {
      state.courier = data.Name
      data.Role === 'Управляющий' ? state.isAdmin = true : state.isAdmin = false
    },
    AUTH_ERROR(state, data) {
      state.auth_error = data
    },
    GET_ORDERS(state, data) {
      state.orders = data
    },
    GET_ORDER(state, data) {
      state.order = data
    },
    GET_DELAY(state, data) {
      state.delay = data
    },
    GET_COURIERS(state, data) {
      state.couriers = data
    },
    GET_COURIER(state, data) {
      state.courier_card = data
    },
    GET_NEW_CODE(state, data) {
      state.courier_card = { ...state.courier_card, ...data }
    },
    SET_COURIER_ID(state, data) {
      state.courier_id = data
    },
    SET_ORDER_ID(state, data) {
      state.order_id = data
    },
    CLEAR_CARD(state) {
      state.courier_card = []
    },
    GET_SALARY(state, data) {
      state.salary = data
    },
    EXIT_APP(state) {
      localStorage.removeItem('sessionId')
      localStorage.removeItem('pin')
      state.courier = ''
      state.orders = []
      state.order = {}
      state.order_id = ''
      state.salary = []
    },
    SET_MENU_STATE(state, data) {
      state.menuOpened = data
    },
    SHOW_SUCCESS_ORDERS(state, data) {
      state.showSuccessOrders = data
    },
  },
  actions: {
    CHECK_AUTH({ state, commit }, pin) {
      if (state.auth_error) {
        commit('AUTH_ERROR', false)
      }
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.CHECK_AUTH,
            {
              params: { Code: pin },
              auth: state.auth,
              validateStatus: function (status) {
                return status == 400 || (status >= 200 && status < 300)
              }
            }
          )
          .then((response) => {
            if (response.status === 200) {
              commit('CHECK_AUTH', response.data)
              localStorage.setItem('pin', pin)
              localStorage.setItem('sessionId', response.data.SessionId)
              resolve(response)
            } else if (response.data.КодОшибки === 1003 || response.data.КодОшибки === 3001 || response.data.КодОшибки === 3000) {
              if (router.currentRoute.path !== '/') {
                router.push('/')
              } else {
                commit('AUTH_ERROR', true)
                throw new Error(response)
              }
            } else {
              commit('AUTH_ERROR', true)
              throw new Error(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GET_ORDERS({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.GET_ORDERS,
            {
              params: { SessionId: localStorage.getItem('sessionId') },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_ORDERS', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GET_ORDER({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.GET_ORDER,
            {
              params: { OrderId: id },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_ORDER', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GET_COURIERS({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.GET_COURIERS,
            {
              params: { SessionId: localStorage.getItem('sessionId') },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_COURIERS', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GET_COURIER({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.GET_COURIER,
            {
              params: { UUID: id },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_COURIER', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GET_NEW_CODE({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.GET_NEW_CODE,
            {
              params: { UUID: id },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_NEW_CODE', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    EDIT_COURIER({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(state.API.EDIT_COURIER,
            {
              UUID: state.courier_id, Name: payload.Name, Birthdate: payload.Birthdate, Phone: payload.Phone, AccessCode: state.courier_card.КодДоступа, Fired: payload.Fired
            },
            {
              auth: state.auth
            }
          )
          .then(function (response) {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    SET_COURIER_ID({ commit }, id) {
      commit('SET_COURIER_ID', id)
    },
    CLEAR_CARD({ commit }) {
      commit('CLEAR_CARD')
    },
    SET_ORDER_ID({ commit }, id) {
      commit('SET_ORDER_ID', id)
    },
    ORDER_DONE({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.ORDER_DONE,
            {
              params: { SessionId: localStorage.getItem('sessionId'), OrderId: state.order_id, Time: payload.time, distance: payload.distance, PaymentMethod: payload.payment, coords: payload.coords },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_ORDER', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    ORDER_DELAY({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.ORDER_DELAY,
            {
              params: { SessionId: localStorage.getItem('sessionId'), OrderId: state.order_id },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_DELAY', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GET_SALARY({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.API.GET_SALARY,
            {
              params: { SessionId: localStorage.getItem('sessionId') },
              auth: state.auth
            }
          )
          .then(function (response) {
            commit('GET_SALARY', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    EXIT_APP({ commit }) {
      commit('EXIT_APP')
    },
    SET_MENU_STATE({ commit }, val) {
      commit('SET_MENU_STATE', val)
    },
    SHOW_SUCCESS_ORDERS({ commit }, val) {
      commit('SHOW_SUCCESS_ORDERS', val)
    },
  },

  getters: {
    isAdmin: (state) => {
      return state.isAdmin
    }
  }
})
