import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/Order.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/Orders.vue')
  },
  {
    path: '/courier',
    name: 'Courier',
    component: () => import('../views/Courier.vue')
  },
  {
    path: '/Couriers',
    name: 'Couriers',
    component: () => import('../views/Couriers.vue')
  },
  {
    path: '/salary',
    name: 'Salary',
    component: () => import('../views/Salary.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
