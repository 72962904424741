<template>
  <div class="menu" :class="{ 'is-opened': isOpened }" @click.self="closeMenu">
    <ul class="menu__list">
      <li v-for="(item, itemIndex) in menu" :key="itemIndex" @click="closeMenu">
        <router-link :to="item.route" class="menu__item" @click.native="handleMenuClick(item)" v-if="item.isShow">
          {{ item.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    isOpened: {
			type: Boolean,
			required: true
		}
  },
  data () {
    return {
      menu: [
        {
          title: 'Курьеры',
          route: '/couriers',
          isShow: false
        },
        {
          title: 'Мои заказы',
          route: '/orders',
          isShow: true
        },
        {
          title: 'Выданные заказы',
          route: '/orders',
          filter: true,
          isShow: true
        },
        {
          title: 'Зарплата',
          route: '/salary',
          isShow: true
        },
        {
          title: 'О программе',
          route: '/about',
          isShow: true
        },
        {
          title: 'Выход',
          route: '/',
          exit: true,
          isShow: true
        },
      ]
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  updated () {
    this.setShow()
  },
  methods: {
    setShow () {
      this.menu[0].isShow = this.isAdmin
    },
    closeMenu () {
      this.$store.dispatch('SET_MENU_STATE', false)
    },
    handleMenuClick(item) {
      // Выданные заказы
      item.filter ? this.$store.dispatch('SHOW_SUCCESS_ORDERS', true) : this.$store.dispatch('SHOW_SUCCESS_ORDERS', false)

      // Выход
      item.exit ? this.$store.dispatch('EXIT_APP') : true
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0);
    transition: background-color $menu-open ease-out 0s, z-index 0s linear 0.35s;
    z-index: -1;
    &.is-opened {
      background-color: rgba(#000, 0.6);
      z-index: 1;
      transition: background-color $menu-open ease-out 0s, z-index 0s linear 0s;
      .menu__list {
        transform: translateX(0%);
      }
    }
    &__list {
      padding: 0 10px;
      background-color: #fff;
      width: 260px;
      height: 100%;
      margin-left: auto;
      transform: translateX(100%);
      transition: transform $menu-open ease-out 0s;
    }
    &__item {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #000;
      padding: 14px 10px;
      border-bottom: 1px solid #ddd;
    }
  }
</style>
